.button {
  position: relative;
  z-index: 2;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: var(--btn-hgt, rem(40));
  min-width: var(--btn-min-wdh, rem(150));

  padding: var(--btn-pdd, #{0 rem(30)});

  font-size: var(--btn-font-size, rem(14));
  font-weight: 600;

  white-space: nowrap;

  border: rem(2) solid currentColor;
  border-radius: $radius;
  background: transparent;

  outline: none;
  user-select: none;

  &.--beck {
    gap: rem(8);

    min-width: auto;
    width: max-content;
    height: auto;

    padding: 0;

    font-size: rem(15);
    font-weight: 500;

    color: $primary;

    border: none;

    &:before {
      font-size: rem(18);
      content: '←';
    }
  }
}

@include only-mouse {
  .button {
    &:hover {
      &.--beck {
        color: $secondary;
      }
    }
  }
}

