@import "https://rsms.me/inter/inter.css";
.guides {
  padding: var(--gutterX4, 6rem) 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:root {
  --container-limit: 73.125rem;
  --container-gutter: var(--gutterX2, 1.5rem);
}

@keyframes in-transform {
  0% {
    opacity: var(--in-transform-opacity-start, 0);
    transform: var(--in-transform-transform-start, translate(-100vw, 0));
  }

  100% {
    opacity: var(--in-transform-opacity-end, 1);
    transform: var(--in-transform-transform-end, translate(0, 0));
  }
}

@keyframes smooth-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color .3s linear;
}

html {
  text-align: left;
  letter-spacing: normal;
  color: #182235;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  scroll-behavior: smooth;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}

@media (min-width: 992px) {
  html {
    font-size: 126%;
  }
}

body {
  padding: var(--header-height) 0 0;
  background: var(--main-bg, #fff);
  flex-flow: column;
  margin: 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

a {
  color: var(--link-color, currentColor);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  transition: color .3s linear;
}

@media (pointer: fine) {
  a:hover {
    color: var(--link-hover-color, #840177);
  }
}

b, strong {
  font-weight: bold;
}

mark {
  color: currentColor;
  background: unset;
}

p {
  max-width: var(--p-max-width, unset);
  margin: var(--p-margin, 0);
  font-weight: var(--p-font-weight, normal);
  font-size: var(--p-font-size, 1rem);
  line-height: var(--p-line-height, 1.38);
}

::selection {
  color: #fff;
  background-color: #840177;
}

.container {
  position: var(--container-position, unset);
  display: var(--container-block, flex);
  align-items: var(--container-align-items, flex-start);
  justify-content: var(--container-justify-content, flex-start);
  flex-flow: var(--container-flex-flow, column);
  gap: var(--container-gap, 0);
  width: 100%;
  max-width: var(--container-limit, auto);
  padding-right: var(--container-gutter, 0);
  padding-left: var(--container-gutter, 0);
  margin-left: auto;
  margin-right: auto;
}

@media (max-height: 420px) {
  @supports (padding: max(0px)) {
    .container {
      padding-left: max(16px, env(safe-area-inset-left) );
      padding-right: max(16px, env(safe-area-inset-right) );
    }
  }
}

.button {
  z-index: 2;
  height: var(--btn-hgt, 2.5rem);
  min-width: var(--btn-min-wdh, 9.375rem);
  padding: var(--btn-pdd, 0 1.875rem);
  font-size: var(--btn-font-size, .875rem);
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: .125rem solid;
  border-radius: .625rem;
  outline: none;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: inline-flex;
  position: relative;
}

.button.--beck {
  min-width: auto;
  width: max-content;
  height: auto;
  color: #182235;
  border: none;
  gap: .5rem;
  padding: 0;
  font-size: .9375rem;
  font-weight: 500;
}

.button.--beck:before {
  content: "←";
  font-size: 1.125rem;
}

@media (pointer: fine) {
  .button:hover.--beck {
    color: #840177;
  }
}

.layout {
  justify-content: center;
  display: flex;
}

.guides {
  display: grid;
}

.guides__caption {
  margin: .75rem 0 2rem;
  font-size: 1.75rem;
  font-weight: bold;
}

.guides__caption.--sub {
  margin: 0 0 1.25rem;
  font-size: 1.125rem;
}

.guides__list {
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

.guide-card {
  --p-max-width: 11.25rem;
  --p-font-size: .875rem;
  padding: var(--gutter, 1rem);
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  background: #f1f2f3;
  border: .0625rem solid #edeff1;
  border-radius: .625rem;
  gap: 1rem;
  display: inline-grid;
}

.guide-card.--select {
  color: #fff;
  background: #840177;
}

.guide-card__name {
  font-size: 1rem;
  font-weight: 800;
}

.guide-card__text {
  font-size: .875rem;
  font-weight: 400;
}

.guide-card__bottom, .guide-card__item {
  align-items: center;
  gap: var(--gutterX2, 1.5rem);
  display: inline-flex;
}

.guide-card__item {
  gap: .625rem;
  font-size: .8125rem;
}

.guide-card__item.--counter:before {
  width: 1.5rem;
  height: 1.5rem;
  content: attr(data-count);
  border: .0625rem solid;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: .125rem;
  display: inline-flex;
}

.guide-card__item.--time svg {
  width: 1.5rem;
  height: 1.5rem;
}

.about-me {
  --link-hover-color: #182235;
  z-index: 1010;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-me__wrap {
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #22252ee6;
  align-items: center;
  gap: 3rem;
  padding: 3rem;
  display: flex;
  position: relative;
  box-shadow: 0 0 0 .4375rem #fff;
}

.about-me__main {
  gap: .625rem;
  display: inline-grid;
}

.about-me__caption {
  color: #fff;
  flex-flow: column;
  margin: 0 0 1.5rem;
  font-size: 2.375rem;
  font-weight: 800;
  display: inline-flex;
}

.about-me__img {
  width: 11.25rem;
  min-width: 11.25rem;
  height: 11.25rem;
  min-height: 11.25rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 0 0 .4375rem #fff;
}

.about-me__links {
  color: #fff;
  align-items: center;
  gap: 1.25rem;
  font-weight: 500;
  display: inline-flex;
}

.about-me__links mark {
  min-width: 6.25rem;
  font-weight: 700;
  display: inline-flex;
}

.about-me__link {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: .0625rem;
  text-align: center;
  background: none;
  border: .125rem solid;
  border-radius: .25rem;
  margin: 0;
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
}

.about-me__link:hover {
  color: #182235;
  background: #fff;
  border-color: #fff;
}

.about-me__canvas {
  z-index: -1;
  width: 18.75rem;
  height: 9.375rem;
  opacity: .8;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: 3rem;
}

.about-me__close {
  z-index: -1;
  background: #84017799;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-me__close span {
  max-width: 25rem;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  filter: drop-shadow(0 0 .625rem #182235);
  margin-top: -10%;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.5;
}

/*# sourceMappingURL=index.ec477395.css.map */
