.about-me {
  --link-hover-color: #{$primary};

  position: fixed;
  inset: auto 0 0;
  z-index: z-index(modal) +$z-index-step;

  &__wrap {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    gap: rem(48);

    padding: rem(48);

    background: rgba(#22252E, 0.9);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 0 rem(7) $light;
  }

  &__main {
    display: inline-grid;
    gap: rem(10);
  }

  &__caption {
    display: inline-flex;
    flex-flow: column;
    margin: 0 0 rem(24);

    font-size: rem(38);
    font-weight: 800;
    color: $light;
  }

  &__img {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: rem(180);
    min-width: rem(180);
    height: rem(180);
    min-height: rem(180);

    border-radius: 50%;

    box-shadow: 0 0 0 rem(7) $light;
    overflow: hidden;
  }

  &__links {
    display: inline-flex;
    align-items: center;
    gap: rem(20);

    //margin-top: rem(10);

    color: $light;
    font-weight: 500;

    mark {
      display: inline-flex;
      min-width: rem(100);
      font-weight: 700;
    }
  }

  &__link {
    margin: 0;
    padding: rem(4) rem(8);

    font-size: rem(13);
    text-transform: uppercase;
    color: $light;
    font-weight: 700;
    letter-spacing: rem(1);
    text-align: center;

    border-radius: rem(4);
    border: rem(2) solid currentColor;
    background: transparent;

    &:hover {
      color: $primary;
      border-color: $light;
      background: $light;
    }
  }

  &__canvas {
    position: absolute;
    inset: auto rem(48) rem(48) auto;
    z-index: -1;

    width: rem(300);
    height: rem(150);
    opacity: 0.8;
  }

  &__close {
    position: fixed;
    inset: 0;
    z-index: -1;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($secondary, 0.6);

    span {
      //position: absolute;
      //inset: 50% 50% 0 0;
      max-width: rem(400);

      margin-top: -10%;

      font-weight: 600;
      letter-spacing: 1px;
      font-size: rem(30);
      line-height: 1.5;
      color: $light;
      text-align: center;

      filter: drop-shadow(0 0 rem(10) $dark);
    }
  }
}
