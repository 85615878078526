@use 'sass:math';

// Function for convert to em & rem
$baseFontSize: 16;

//div
@function dv($numb1, $numb2) {
  @return math.div($numb1, $numb2);
}

//to rem
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return dv($pixels, $context) * 1rem;
}

//to em
@function em($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return dv($pixels, $context) * 1em;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

@function use($key) {
  @return map-get($theme-map, $key);
}

@function z-index($key) {
  @return map-get($z-index, $key);
}

/// Replace `$search` with `$replace` in `$string`
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Retrieve color Sass maps
@function clr($key: 'primary') {
  @return map-get($colors, $key);
}

// Retrieve screens Sass maps
@function screens($key: '') {
  @return map-get($wideScreens, $key);
}


@function get-value($n) {
  @return $n / ($n * 0 + 1);
}

// pretty simple eh?
@function get-line-height($font-size, $line-height) {
  @return get-value($line-height / $font-size);
}

/*
.foo {
  font-size: 26px;
  line-height: get-line-height(26px, 32px); //1.23077
}
*/

@function random-range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}
